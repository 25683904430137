import React, { useEffect } from 'react';

import '../css/pages/aplicacion.css';

const Aplicacion = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="op-body-contenedor">
      <div className="op-fondo">
        <label>T&eacute;rminos y Condiciones</label>
        <span>
          T&eacute;rminos y condiciones aplicable a los usuarios de la
          aplicaci&oacute;n "WUASHO"
        </span>
      </div>
      <div className="op-info">
        <p>
          El Usuario Solicitante acepta todos los T&eacute;rminos y Condiciones
          establecidos en el presente documento, antes de utilizar la
          Aplicaci&oacute;n Wuasho, as&iacute; como cualquier
          actualizaci&oacute;n futura de dicha aplicaci&oacute;n y/o de las
          presentes pol&iacute;ticas. De igual modo, el Usuario Solicitante
          acepta las pol&iacute;ticas complementarias y/o espec&iacute;ficas
          vigentes que pudieren existir para servicios espec&iacute;ficos,
          as&iacute; como las actualizaciones de las mismas, en el momento que
          ello ocurriere.
        </p>
        <p>
          En caso el Usuario Solicitante no est&eacute; de acuerdo con los
          T&eacute;rminos y Condiciones siguientes, deber&aacute; abstenerse de
          utilizar esta aplicaci&oacute;n y sus actualizaciones, toda vez que la
          utilizaci&oacute;n de la Aplicaci&oacute;n Wuasho implica
          manifestaci&oacute;n de conformidad del Usuario con todos los
          T&eacute;rminos y Condiciones. En caso el Usuario Solicitante
          contin&uacute;e utilizando la Aplicaci&oacute;n Wuasho, se
          entender&aacute; que las ha aceptado completamente.
        </p>
        <span>DECLARACIONES DE LOS USUARIOS SOLICITANTES</span>
        <p>
          El Usuario Solicitante declara y afirma que tiene por lo menos 18 años
          de edad, o es un menor de edad con capacidad jur&iacute;dica, o posee
          consentimiento legal paterno o de su tutor, de acuerdo a lo
          establecido por el C&oacute;digo Civil Peruano para obligarse con los
          presentes T&eacute;rminos y Condiciones, adem&aacute;s de acatarlos y
          cumplirlos.
        </p>
        <p>
          Al descargar y/o usar la Aplicaci&oacute;n Wuasho, usted manifiesta y
          garantiza lo siguiente:
        </p>
        <ul>
          <li>
            Que tiene el derecho, autoridad y capacidad para cumplir con todos
            los T&eacute;rminos y Condiciones.
          </li>
          <li>
            Que est&aacute; en la posibilidad de pagar por el servicio brindado.
          </li>
          <li>
            Que la informaci&oacute;n que proporcione a Wuasho ser&aacute; veraz
            y precisa.
          </li>
          <li>
            Que la utilizaci&oacute;n de la aplicaci&oacute;n es a t&iacute;tulo
            personal, no siendo posible que sea utilizado por un tercero,
            diferente a usted.
          </li>
        </ul>
        <span>
          AUTORIZACI&Oacute;N DE USO DE LA APLICACI&Oacute;N, REQUERIMIENTOS
          T&Eacute;CNICOS Y RESTRICCIONES DE USO
        </span>
        <p>
          El acceso a la aplicaci&oacute;n Wuasho es gratuito, El Usuario
          Solicitante ser&aacute; responsable del costo de su servicio
          telef&oacute;nico y de Internet, y deber&aacute; consultar con las
          empresas de telecomunicaciones respectivas las tarifas por el uso de
          planes de voz y/o de datos.
        </p>
        <p>
          Para el acceso a los contenidos de la Aplicaci&oacute;n Wuasho
          ser&aacute; necesario el registro del Usuario. Por ello, para acceder
          a los servicios de la aplicaci&oacute;n, el usuario deber&aacute;
          contar con un smartphone con sistema operativo Android o Apple y
          completar todos los campos del formulario de registro con datos
          v&aacute;lidos, pero Wuasho no se responsabiliza por la certeza de los
          datos personales que los usuarios pongan, o pueden registrarse con los
          datos de su cuenta de Gmail. Los usuarios registrados garantizan y
          responden, en cualquier caso, por la veracidad, exactitud, vigencia y
          autenticidad de los datos personales puestos a disposici&oacute;n de
          Wuasho.
        </p>
        <p>
          El Usuario Solicitante se obliga a no modificar, reproducir, copiar,
          realizar ingenier&iacute;a inversa, revertir la ingenier&iacute;a,
          redise&ntilde;ar, descompilar, adaptar, traducir, preparar trabajos
          derivados de la Aplicaci&oacute;n o usar la Aplicaci&oacute;n para
          desarrollar cualquier software u otros materiales basados en el mismo.
          Asimismo, se obliga a utilizar la Aplicaci&oacute;n &uacute;nicamente
          en la forma permitida en estos T&eacute;rminos y Condiciones. Wuasho
          declara que la Aplicaci&oacute;n se encuentra protegida por la
          legislaci&oacute;n vigente en materia de Derechos de Autor, la misma
          que el Usuario Solicitante declara y afirma conocer y se compromete a
          cumplir a cabalidad.
        </p>
        <p>
          El Usuario Solicitante acepta y reconoce que Wuasho podr&aacute;
          realizar acciones legales para requerir y asegurar el cumplimiento de
          todas las disposiciones establecidas en los presentes T&eacute;rminos
          y Condiciones, as&iacute; como solicitarle el resarcimiento por
          da&ntilde;os y perjuicios ocasionados por incumplimiento de sus
          obligaciones legales y/o derivada del presente documento (ocasionado
          por fraude, uso indebido del perfil, entre otros), incluyendo tal
          concepto la indemnizaci&oacute;n por da&ntilde;o emergente y lucro
          cesante.
        </p>
        <p>
          El Usuario Solicitante reconoce el derecho de Wuasho a proceder
          inmediatamente y sin notificaci&oacute;n previa a retirarlo de la
          Aplicaci&oacute;n y/o a bloquear su acceso a &eacute;ste, en el caso
          de que el uso que le d&eacute; a la Aplicaci&oacute;n sea inadecuado,
          il&iacute;cito, abusivo o viole los presentes T&eacute;rminos y
          Condiciones, sus documentos conexos y/o las actualizaciones de estos o
          en general los derechos de terceros, las leyes y los reglamentos
          vigentes, en estos casos, Wuasho se reserva el derecho de realizar las
          acciones legales correspondientes.
        </p>
        {/*<span>FUNCIONES DE LA APLICACI&Oacute;N</span>
         <p>
          Por medio de la Aplicaci&oacute;n, el Usuario Solicitante podr&aacute;
          solicitar servicios de lavado de prendas a las lavander&iacute;as
          afiliadas a la Aplicaci&oacute;n m&oacute;vil de Wuasho as&iacute;
          como conocer el nombre, ubicaci&oacute;n y otra informaci&oacute;n
          correspondiente de la lavander&iacute;a.
        </p>
        <p>
          Las lavander&iacute;as son independientes y libres de aceptar o
          rechazar las solicitudes de servicios que env&iacute;e el Usuario
          Solicitante, incluso si las lavander&iacute;as aparecen como
          disponibles en la Aplicaci&oacute;n.
        </p>
        <p>
          El Usuario Solicitante podr&aacute; tambi&eacute;n conocer, la
          calificaci&oacute;n consolidada de las lavander&iacute;as, en base a
          las calificaciones brindadas por otros Usuarios Solicitantes.
        </p>
        <p>
          El Usuario Solicitante es libre en todo momento de enviar solicitudes
          de servicio a trav&eacute;s de la Aplicaci&oacute;n. La
          relaci&oacute;n entre la lavander&iacute;a y el Usuario Solicitante es
          aut&oacute;noma e independiente, no siendo responsable Wuasho, ni
          directa ni indirectamente, por lo que pudiese ocurrir en la referida
          relaci&oacute;n.
        </p> 
        <p>
          Por medio de la Aplicaci&oacute;n, el Usuario Solicitante podr&aacute;
          registrar los mantenimiento realizados a los equipos de sus clientes.
        </p>
        <p>
          La aplicaci&oacute;n permitira almacenar imagenes e informaci&oacute;n
          detallada del servicio de mantenimiento.
        </p>
        <p>
          La aplicaci&oacute;n genera un historial de mantenimientos realizados.
        </p>
        <span>MODALIDADES DE PAGO DEL TRASLADO DE USUARIOS SOLICITANTES</span>
        <p>
          El precio de las tarifas es mostrado en soles antes de solicitar el
          servicio, siempre y cuando el Usuario Solicitante señale el lugar de
          recogo y entrega de las prendas.
        </p>
        <p>El pago se puede realizar mediante dos modalidades:</p>
        <ul>
          <li>
            A través de las lavander&iacute;as mediante el uso de efectivo, y
          </li>
          <li>Mediante transferencias interbancarias (YAPE y PLIN)</li>
        </ul>
        <p>
          La modalidad de pago podr&aacute; ser seleccionada antes de solicitar
          el servicio.
        </p>*/}
        <span>
          DERECHOS DE PROPIEDAD INTELECTUAL Y OBLIGACIONES DE CONTENIDOS
        </span>
        <p>
          Wuasho y Soluciones OP son licenciatarios de la propiedad intelectual
          de la Aplicaci&oacute;n, incluyendo los derechos de autor sobre el
          c&oacute;digo fuente y el c&oacute;digo objeto de la Aplicaci&oacute;n
          y los derechos sobre todas las marcas, logos, slogans, dise&ntilde;os,
          informaci&oacute;n, m&uacute;sica, sonidos, nombres comerciales y otra
          Propiedad Intelectual e Industrial que forme parte del contenido de la
          Aplicaci&oacute;n. En consecuencia, el Usuario Solicitante se
          encuentra prohibido y se compromete expresamente a no copiar,
          reproducir, modificar, alterar, distribuir, suprimir o de cualquier
          forma usar dicha propiedad intelectual en forma distinta a la
          establecida en estos T&eacute;rminos y Condiciones.
        </p>
        <span>ACTUALIZACIONES DE LA APLICACI&Oacute;N</span>
        <p>
          Usted es responsable de adquirir y actualizar el hardware compatible o
          los dispositivos necesarios para acceder y utilizar los servicios
          as&iacute; como cualquier actualizaci&oacute;n que Wuasho realice a la
          aplicaci&oacute;n.
        </p>
        <p>
          La Aplicaci&oacute;n ha sido programada de tal modo que es capaz de
          conectarse autom&aacute;ticamente a Internet y buscar actualizaciones
          disponibles, las cuales pueden descargarse autom&aacute;ticamente,
          siempre y cuando la configuraci&oacute;n del dispositivo as&iacute; lo
          permita.
        </p>
        <p>
          La actualizaci&oacute;n de la Aplicaci&oacute;n no tiene un costo, sin
          embargo podr&iacute;an haber costos asociados al plan de datos del
          Usuario Solicitante para dicho proceso de descarga, siendo esto
          &uacute;ltimo responsabilidad plena del Usuario Solicitante.
        </p>
        <span>CONEXI&Oacute;N A INTERNET Y SERVICIOS EN L&Iacute;NEA</span>
        <p>
          La Aplicaci&oacute;n requiere estar conectada a Internet para su
          funcionamiento.
        </p>
        <span>POL&Iacute;TICAS DE PRIVACIDAD</span>
        <p>
          Responsable de sus Datos Personales. Soluciones OP, ser&aacute; el
          &uacute;nico responsable del tratamiento, uso, almacenamiento y
          divulgaci&oacute;n de sus datos personales, conforme a lo establecido
          en las presentes pol&iacute;ticas de privacidad.
        </p>
        <p>Datos Personales que recaba y almacena Wuasho</p>
        <ul>
          <li>Nombres</li>
          <li>
            Fecha en la que descarg&oacute; la Aplicaci&oacute;n y fecha de
            registro
          </li>
          <li>N&uacute;mero de tel&eacute;fono celular</li>
          <li>Correo electr&oacute;nico</li>
          {/* <li>Direcci&oacute;n IP</li>
          <li>
            Direcci&oacute;n f&iacute;sica, fecha y hora en el que el Usuario
            Solicitante solicita un servicio a trav&eacute;s de la
            Aplicaci&oacute;n
          </li> */}
          <li>
            B&uacute;squedas y solicitudes de servicios que el Usuario
            Solicitante haya realizado a trav&eacute;s de la Aplicaci&oacute;n
          </li>
          <li>
            Servicios solicitados y servicios completados a trav&eacute;s de la
            Aplicaci&oacute;n
          </li>
          {/* <li>
            Ubicaci&oacute;n del tel&eacute;fono m&oacute;vil, celular o tableta
            en tiempo real lo que puede revelar la ubicaci&oacute;n del Usuario
            Solicitante en tiempo real (Geolocalizaci&oacute;n), datos que
            ser&aacute;n v&aacute;lidos como direcci&oacute;n recurrente.
            Adicionalmente el celular deber&aacute; encontrarse encendido y con
            el servicio de GPS activo cuando el Usuario Solicitante registre una
            direcci&oacute;n
          </li>
          <li>
            Calificaciones que realicen los Usuarios Solicitante a las
            lavander&iacute;as
          </li> */}
          <li>
            Salvo lo descrito en el presente documento, Wuasho no recaba datos
            sensibles. Si el Usuario Solicitante publica o postea cualquier dato
            sensible en la Aplicaci&oacute;n ser&aacute; bajo su absoluta
            responsabilidad y Wuasho se reserva el derecho de suprimirlo.
          </li>
        </ul>
        <p>
          Uso de sus Datos Personales. Los datos personales con los que
          contar&aacute; Wuasho se administrar&aacute;n y se tratar&aacute;n
          para las siguientes finalidades, las que son estrictamente necesarias
          para el cumplimiento de la relaci&oacute;n jur&iacute;dico-comercial
          que el Usuario Solicitante mantiene con Wuasho y para el uso de la
          Aplicaci&oacute;n de acuerdo con sus funcionalidades. El Usuario
          Solicitante manifiesta aceptar y dar consentimiento libre, previo,
          expreso, inequ&iacute;voco e informado para el tratamiento de sus
          datos personales a favor de Wuasho para los siguientes fines:
        </p>
        <ul>
          {/* <li>
            Permitir la coordinaci&oacute;n y el reconocimiento por parte de la
            lavander&iacute;a cuando el Usuario Solicitante solicite un servicio
            a trav&eacute;s de la Aplicaci&oacute;n.
          </li> */}
          <li>
            Medir la calidad de los servicios de la aplicaci&oacute;n que el
            Usuario Solicitante haya contratado y canalizar adecuadamente sus
            solicitudes de servicio
          </li>
          {/* <li>
            Para compartir su ubicaci&oacute;n e informaci&oacute;n con las
            lavander&iacute;as que cuenten con el sistema Wuasho y que
            &eacute;stos puedan evaluarlo y contactarlo cuando el Usuario
            Solicitante env&iacute;e solicitudes de servicios a trav&eacute;s de
            la Aplicaci&oacute;n
          </li> */}
          <li>Fines estad&iacute;sticos</li>
          <li>
            Mejorar la calidad de la Aplicaci&oacute;n y los servicios de Wuasho
          </li>
        </ul>
        <p>
          En adici&oacute;n a lo anterior, al registrarse el Usuario Solicitante
          en la Aplicaci&oacute;n Wuasho, se entender&aacute; que acepta de
          manera expresa que los datos de contacto que proporcione podr&aacute;n
          ser utilizados para enviar notificaciones, avisos, propaganda o
          publicidad sobre productos de Solucines OP para env&iacute;o de
          informaci&oacute;n y noticias o de temas que Wuasho considere que
          pueden interesarle, as&iacute; como noticias, comunicaciones o
          publicidad de Wuasho o sus empresas relacionadas.
        </p>
      </div>
    </div>
  );
};

export default Aplicacion;
