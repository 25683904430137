import React from 'react';
import { Link } from 'react-router-dom';

import '../css/pages/empresa.css';

const Empresa = () => {
  return (
    <div className="op-body-contenedor">
      <div className="op-empresa">
        <div className="op-titulo">
          <label>Con Wuasho empresa llega a más clientes</label>
        </div>
        <div className="op-fondo-empresa"></div>
      </div>
      <div className="op-beneficios">
        <div className="op-card">
          <div className="icon graph"></div>
          <div className="text">
            Aumenta tus ventas sin aumentar tus costos operativos
          </div>
        </div>
        <div className="op-card">
          <div className="icon startup"></div>
          <div className="text">
            Te ofrecemos plataformas rápidas e intuitivas
          </div>
        </div>
      </div>
      <div className="op-requisitos">
        <label>Requisitos para afiliarte</label>
        <p>Persona natural con Documento de Identidad</p>
        <p>Persona Jur&iacute;dica con RUC</p>
      </div>
      <div className="op-terminos">
        <Link to={'/terminos'}>T&eacute;rminos y Condiciones</Link>
      </div>
    </div>
  );
};

export default Empresa;
