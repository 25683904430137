import React from 'react';
import { NavLink } from 'react-router-dom';

import '../css/components/header.css';

const Header = () => {
  const handleHeader = (e) => {
    e.target
      .closest('.op-header-contenedor')
      .querySelector("input[type='checkbox']").checked = false;
  };

  return (
    <div className="op-header">
      <div className="op-header-contenedor">
        <input type="checkbox" id="checkMenu" />
        <label htmlFor="checkMenu" className="checkButton">
          <span></span>
          <span></span>
          <span></span>
        </label>
        <span className="op-logo">Soluciones OP</span>
        <ul>
          <li>
            <NavLink to={'/'} onClick={(e) => handleHeader(e)}>
              Inicio
            </NavLink>
          </li>
          {/* <li>
            <NavLink to={'/aplicacion'} onClick={(e) => handleHeader(e)}>
              Wuasho
            </NavLink>
          </li> */}
          <li>
            <NavLink to={'/empresa'} onClick={(e) => handleHeader(e)}>
              Wuasho Empresa
            </NavLink>
          </li>
          <li>
            <NavLink to={'/soporte'} onClick={(e) => handleHeader(e)}>
              Soporte
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
